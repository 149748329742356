import * as React from "react"
import useStyles from "./Icon.styles"
import Icons from "./icons"
import clsx from "clsx"

export interface IIconBaseProps {
  size?: React.CSSProperties["width"]
  className?: string
}

export interface IIconTypeProps {
  type: keyof typeof Icons
  icon?: never
}

export interface IIconIconProps {
  type?: never
  icon: () => JSX.Element
}

export type IIconProps = IIconBaseProps & (IIconTypeProps | IIconIconProps)

const Icon: React.FC<IIconProps> = ({ type, icon: Icon, size, className }) => {
  const classes = useStyles()
  const TypeIcon = type ? Icons[type] : Icon

  return (
    <div
      className={clsx(classes.root, className)}
      style={size ? { width: size, height: size } : undefined}
    >
      <TypeIcon />
    </div>
  )
}

export default Icon
