import React, { useEffect, useState } from "react"
import {
  Notification as BaseNotification,
  INotificationProps,
} from "@align-cpf/uikit"
import useStyles from "./fullScreenNotification.styles"
import { getProductName } from "library/common/types/utils/annotations"
import { useTranslation } from "react-i18next"
import { useUrlParamContext } from "utils/UrlParamContext/UrlParamContext"

const FullScreenNotification = () => {
  const [showNotification, setShowNotification] = useState(false)
  const [isLeavingNotification, setIsLeavingNotification] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    const messageHandler = (msgEvent: MessageEvent) => {
      if (msgEvent?.data !== "BACK_TO_LANDING") return
      setShowNotification(true)
      setIsLeavingNotification(true)
    }

    window.addEventListener("message", messageHandler)
    return () => window.removeEventListener("message", messageHandler)
  }, [])

  const { sourceParam } = useUrlParamContext()
  const hasSourceParam = sourceParam === "web" || sourceParam === "desktop"
  useEffect(() => {
    if (!hasSourceParam) return
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 2000)
  }, [hasSourceParam])

  const Notification = BaseNotification as React.FC<
    Omit<INotificationProps, "title" | "onClose"> &
      Partial<Pick<INotificationProps, "title">>
  >

  if (!showNotification) return null

  return (
    <div className={classes.notificationContainer}>
      <div className={classes.notificationWrapper}>
        <Notification
          delay={0}
          type="warning"
          message={t(
            `login.${isLeavingNotification ? "leaving" : "entering"}_message`,
            {
              productName: getProductName(),
            }
          )}
        />
      </div>
    </div>
  )
}

export default FullScreenNotification
