import { call, put, takeLatest } from "redux-saga/effects"

import { XraysTypes } from "library/common/types/xraysTypes"
import { requestDeleteXRay } from "library/services/xraysApi"
import { deleteActivePatientXray } from "../actions/patient"
import { setImageIDBreadcrumb } from "../actions/breadcrumbs"

interface IDeleteXray {
  payload: string
  type: string
}

function* deleteXray(id: IDeleteXray) {
  try {
    yield call(requestDeleteXRay, id.payload)
    yield put(deleteActivePatientXray(id.payload))
    yield put(setImageIDBreadcrumb(""))
  } catch (error) {
    console.log(error)
  }
}

export default function* watchXRays() {
  yield takeLatest(XraysTypes.DELETE_XRAY_START, deleteXray)
}
