import { createAction } from "typesafe-actions"
import { ResultStatus } from "../types/dataStructureTypes"
import {
  patientTypes,
  IPagination,
  FilterState,
  Status,
  ActivePatientResult,
  PatientListResult,
  Patient,
  PatientMatch,
  MatchingPatient,
} from "../types/patientTypes"

export const getPatientsFromServer = createAction(
  patientTypes.GET_PATIENTS_FROM_SERVER
)()
export const getExternalPatient = createAction(
  patientTypes.GET_EXTERNAL_PATIENT
)<string>()
export const setPatientListResult = createAction(
  patientTypes.SET_PATIENT_LIST_RESULT
)<PatientListResult>()
export const setPatientListResultStatus = createAction(
  patientTypes.SET_PATIENT_LIST_RESULT_STATUS
)<ResultStatus>()
export const setActivePatientResultStatus = createAction(
  patientTypes.SET_ACTIVE_PATIENT_RESULT_STATUS
)<ResultStatus>()
export const setActivePatientResult = createAction(
  patientTypes.SET_ACTIVE_PATIENT_RESULT
)<ActivePatientResult>()
export const requestPatient = createAction(
  patientTypes.REQUEST_PATIENT
)<string>()
export const deleteActivePatientXray = createAction(
  patientTypes.DELETE_ACTIVE_PATIENT_XRAY
)<string>()
export const setInitialState = createAction(patientTypes.SET_INITIAL_STATE)()
export const setPagination = createAction(
  patientTypes.SET_PAGINATION
)<IPagination>()
export const setFilterValues = createAction(patientTypes.SET_FILTER_VALUES)<
  FilterState[]
>()
export const setFilteredStatus = createAction(
  patientTypes.SET_FILTERED_STATUS
)<Status>()
export const setIsPatientMatchingMode = createAction(
  patientTypes.SET_IS_PATIENT_MATCHING_MODE
)<boolean>()
export const clearActivePatientResult = createAction(
  patientTypes.CLEAR_ACTIVE_PATIENT_RESULT
)()
export const overwritePatient = createAction(
  patientTypes.OVERWRITE_PATIENT
)<Patient>()
export const setPatientMatch = createAction(
  patientTypes.SET_PATIENT_MATCH
)<PatientMatch | null>()
export const linkPatient = createAction(
  patientTypes.LINK_PATIENT
)<MatchingPatient>()
