import { ResultStatus } from "./dataStructureTypes"
import { IMeta } from "./serverDataTypes"

export enum patientTypes {
  SET_ACTIVE_PATIENT_RESULT = "@@PATIENT/SET_ACTIVE_PATIENT_RESULT",
  GET_PATIENTS_FROM_SERVER = "@@PATIENT/GET_PATIENTS_FROM_SERVER",
  SET_PATIENT_LIST_RESULT = "@@PATIENT/SET_PATIENT_LIST_RESULT",
  REQUEST_PATIENT = "@@PATIENT/REQUEST_PATIENT",
  DELETE_ACTIVE_PATIENT_XRAY = "@@PATIENT/DELETE_ACTIVE_PATIENT_XRAY",
  SET_INITIAL_STATE = "@@PATIENT/SET_INITIAL_STATE",
  SET_PAGINATION = "@@PATIENT/SET_PAGINATION",
  SET_FILTER_VALUES = "@@PATIENT/SET_FILTER_VALUES",
  SET_FILTERED_STATUS = "@@PATIENT/SET_FILTERED_STATUS",
  SET_PATIENT_LIST_RESULT_STATUS = "@@PATIENT/SET_PATIENT_LIST_RESULT_STATUS",
  SET_ACTIVE_PATIENT_RESULT_STATUS = "@@PATIENT/SET_ACTIVE_PATIENT_RESULT_STATUS",
  TOGGLE_IS_PATIENT_MATCHING_MODE = "@@PATIENT/TOGGLE_IS_PATIENT_MATCHING_MODE",
  CLEAR_ACTIVE_PATIENT_RESULT = "@@PATIENT/CLEAR_ACTIVE_PATIENT_RESULT",
  OVERWRITE_PATIENT = "@@PATIENT/OVERWRITE_PATIENT",
  GET_EXTERNAL_PATIENT = "@@PATIENT/GET_EXTERNAL_PATIENT",
  SET_PATIENT_MATCH = "@@PATIENT/SET_PATIENT_MATCH",
  LINK_PATIENT = "@@PATIENT/LINK_PATIENT",
  SET_IS_PATIENT_MATCHING_MODE = "@@PATIENT/SET_IS_PATIENT_MATCHING_MODE",
}

export interface PatientList {
  id: string
  patientID: string | null
  patientUuid: string | null
  patientName: string | null
  dateOfBirth: string | null
  imageDate: string
  modalities: Record<string, number>
  statusTag?: number
  reportDate?: string
  comments?: string[]
  viewed: boolean
  status?: Status
  links: number
}

export interface PatientListResult {
  resultStatus: ResultStatus
  patientList: PatientList[]
}

export interface Patient {
  patientUuid: string | null
  patientID: string | null
  patientName: string | null
  dateOfBirth: string | null
}

export interface ActivePatientImages {
  resultId: string | null
  report_submitted: string
  viewed: boolean
  statusTag?: number
  generalComment: string
  addedComments: { comment: string; toothName: number }[]
  imageMetadata: IMeta
  imageDate: string
  status: Status
}

export interface ActivePatientResult {
  resultStatus: ResultStatus
  images: ActivePatientImages[] | null
  patient: Patient | null
  links: PatientMatch[]
}

export interface PatientListServerResult {
  data: PatientList[]
}

export interface ActivePatientServerResult {
  data: {
    images: ActivePatientImages[] | null
    patient: Patient
    links: PatientMatch[]
  }
}

export interface IPagination {
  page: number
  perPage: number
}

export interface FilterState {
  section: string
  selected: string[]
  match: (patient: PatientList, selected: string[]) => boolean
}

export interface PatientMatch {
  chartId: string | null
  dateOfBirth: string | null
  externalId: string
  firstName: string | null
  lastName: string | null
  link: string | null
  status?: string
}

export interface PatientMetaRequest {
  links: PatientMatch[]
  patient: Patient
}

export interface MatchingPatient {
  id: string
  matchingPatientId: string | null
}

export enum Status {
  all = "all",
  new = "new",
  confirmed = "confirmed",
  viewed = "viewed",
  uploaded = "uploaded",
}
