import fetch from "library/utilities/fetch"
import { REACT_APP_REVERT_VERSION } from "library/utilities/constants"
import { Kind } from "library/common/types/serverDataTypes"
import { ContextQuery } from "library/common/types/userTypes"

export interface ReanalyzeRequest {
  id: string
  kind?: Kind
  rotate?: number
  isFlipped?: boolean
}

export const requestImageAnalysis = (id: string, params: ContextQuery) =>
  fetch.get(`/result/${id}/?client=frontend`, {
    params: { showHistory: REACT_APP_REVERT_VERSION, ...params },
  })

export const requestReanalyze = (
  data: ReanalyzeRequest,
  params: ContextQuery
) =>
  fetch.post(
    `/reanalyze/`,
    {
      ...data,
      ["frontend-upload"]: true,
    },
    { params }
  )

export const requestPdfReport = (
  id: string,
  lang: string,
  theme: string,
  showVersion: boolean,
  patientUuid: string | null,
  params: ContextQuery
) =>
  fetch.get(
    `/report-pdf/${id}.json?topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&clipboard=1&detections=0.5`,
    { params: { lang, theme, showVersion, patientUuid, ...params } }
  )

export const requestBoneLossPdfReport = (
  id: string,
  lang: string,
  theme: string,
  showVersion: boolean,
  patientUuid: string | null,
  params: ContextQuery
) =>
  fetch.get(
    `/report-pdf/${id}.json?topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&reports=bone-loss&boneLossDetections=0.5&clipboard=1`,
    { params: { lang, theme, showVersion, patientUuid, ...params } }
  )

export const revertImage = (
  resultId: string,
  id: number,
  params: ContextQuery
) => fetch.post("/revert/", { result_id: resultId, id }, { params })
