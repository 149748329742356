import { createAction } from "typesafe-actions"

import {
  ServerDataTypes,
  Comment,
  AnnotationChange,
  IMeta,
  IMoveComment,
  IInferenceStatus,
  BoneLossFormUser,
  ImageFilterTypes,
} from "library/common/types/serverDataTypes"
import { UserChange } from "../types/dataStructureTypes"
import { AnnotationOnTooth, BonelossChange } from "../types/adjustmentTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"

export const addUserChanges = createAction(ServerDataTypes.ADD_USER_CHANGES)<
  UserChange[]
>()
export const deleteUserChange = createAction(
  ServerDataTypes.DELETE_USER_CHANGE
)<{ id: number; deleteNonHSM?: boolean }>()
export const editUserChange = createAction(ServerDataTypes.EDIT_USER_CHANGE)<{
  oldChange: UserChange
  newChange: UserChange
}>()
export const addUserAdditions = createAction(
  ServerDataTypes.ADD_USER_ADDITIONS
)<AnnotationOnTooth[]>()
export const addCariesAdditions = createAction(
  ServerDataTypes.ADD_CARIES_ADDITIONS
)<AnnotationOnTooth[]>()
export const changeUserAddition = createAction(
  ServerDataTypes.CHANGE_USER_ADDITION
)<AnnotationOnTooth>()
export const deleteUserAddition = createAction(
  ServerDataTypes.DELETE_USER_ADDITION
)<AnnotationOnTooth>()
export const deleteUserAdditionById = createAction(
  ServerDataTypes.DELETE_USER_ADDITION_BY_ID
)<number>()
export const deleteUserAdditionByIdSuccess = createAction(
  ServerDataTypes.DELETE_USER_ADDITION_BY_ID_SUCCESS
)<number>()
export const userAddAddedTeeth = createAction(
  ServerDataTypes.USER_ADD_ADDED_TEETH
)<Tooth[]>()
export const userDeleteAddedTeeth = createAction(
  ServerDataTypes.USER_DELETE_ADDED_TEETH
)<number>()
export const userAddDeletedTeeth = createAction(
  ServerDataTypes.USER_ADD_DELETED_TEETH
)<Tooth[]>()
export const userDeleteDeletedTeeth = createAction(
  ServerDataTypes.USER_DELETE_DELETED_TEETH
)<number>()
export const setMovedTeeth = createAction(ServerDataTypes.SET_MOVED_TEETH)<
  Record<string, number>
>()
export const deleteMovedUserChange = createAction(
  ServerDataTypes.DELETE_MOVED_USER_CHANGE
)<number>()
export const setInitialState = createAction(ServerDataTypes.SET_INITIAL_STATE)()
export const setInitialImageMetaState = createAction(
  ServerDataTypes.SET_INITIAL_IMAGE_META_STATE
)()
export const setGeneralComment = createAction(
  ServerDataTypes.SET_GENERAL_COMMENT
)<string>()
export const addAddedComments = createAction(
  ServerDataTypes.ADD_ADDED_COMMENTS
)<Comment[]>()
export const setToothLoss = createAction(
  ServerDataTypes.SET_TEETH_LOSS
)<string>()
export const setBoneLossCategory = createAction(
  ServerDataTypes.SET_BONE_LOSS_CATEGORY
)<string>()
export const setBoneLossIndex = createAction(
  ServerDataTypes.SET_BONE_LOSS_INDEX
)<string>()
export const setAge = createAction(ServerDataTypes.SET_AGE)<number>()
export const setDiabetes = createAction(ServerDataTypes.SET_DIABETES)<string>()
export const setSmoking = createAction(ServerDataTypes.SET_SMOKING)<string>()
export const setDistribution = createAction(
  ServerDataTypes.SET_EXTEND_OR_DISTRIBUTION
)<string>()
export const setComplications = createAction(
  ServerDataTypes.SET_COMPLEXITY
)<string>()
export const changeAnnotation = createAction(
  ServerDataTypes.CHANGE_ANNOTATION
)<AnnotationChange>()
export const toggleAnnotation = createAction(
  ServerDataTypes.TOGGLE_ANNOTATION
)<number>()
export const flipImage = createAction(ServerDataTypes.FLIP_IMAGE)()
export const toggleFlipImage = createAction(ServerDataTypes.TOGGLE_FLIP_IMAGE)()
export const saveImageMeta = createAction(
  ServerDataTypes.SAVE_IMAGE_META
)<IMeta>()
export const saveBoneLossForm = createAction(
  ServerDataTypes.SAVE_BONE_LOSS_FORM
)<BoneLossFormUser>()
export const moveComments = createAction(
  ServerDataTypes.MOVE_COMMENTS
)<IMoveComment>()
export const setToothBoneloss = createAction(
  ServerDataTypes.SET_TOOTH_BONELOSS
)<BonelossChange>()
export const toggleDisplayHorizontallyFlipped = createAction(
  ServerDataTypes.TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED
)()
export const setInferenceStatus = createAction(
  ServerDataTypes.SET_INFERENCE_STATUS
)<IInferenceStatus>()
export const adjustFilter = createAction(ServerDataTypes.ADJUST_FILTER)<{
  type: ImageFilterTypes
  value: number
}>()
