import { formatISO9075 } from "date-fns"

// We use ISO 8601 date formats throughout the application

export const parseDate = (date?: string | number | Date | null) => {
  if (!date) return
  const result = new Date(date)
  return isNaN(result.getTime()) ? undefined : result
}

export const transformDateAndTime = (date?: string | number | Date) => {
  const parsed = parseDate(date)
  if (!parsed) return
  return formatISO9075(parsed)
}

export const transformDate = (date?: string | number | Date) => {
  const parsed = parseDate(date)
  if (!parsed) return
  return formatISO9075(parsed, { representation: "date" })
}
