import { connect } from "react-redux"

import { UrlParamContext } from "./UrlParamContext"
import { setUserInfo } from "library/common/actions/user"
import { setLang } from "core/i18n/actions"
import { getTheme } from "library/common/selectors/user"
import { RootState } from "core/store/configureStore"

const mapStateToProps = (store: RootState) => ({
  theme: getTheme(store),
})

export default connect(mapStateToProps, {
  setUserInfo,
  setLang,
})(UrlParamContext)
