import localStorage from "library/utilities/localStorage"
import { store } from "core/store/configureStore"

export const defaultRoute = () => {
  const isIteroScannerUser = store.getState().user.isIteroScannerUser
  return (
    sessionStorage.getItem("last_url") ||
    (localStorage.getItem("installer_by_default") || isIteroScannerUser
      ? "/patients"
      : "/upload")
  )
}
