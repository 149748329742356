import { IReportProblem } from "library/common/actions/upload"
import { UserChange } from "library/common/types/dataStructureTypes"
import { ContextQuery } from "library/common/types/userTypes"
import { BoneLossFormFull } from "library/common/types/serverDataTypes"
import fetch from "../utilities/fetch"

export interface IRequestUploadImage {
  meta: {
    fileName: string
  }
  data: string
}

export interface IRequestSendReport {
  resultId: string
  meta?: {
    isImageHorizontallyFlipped?: boolean
    patientID?: string
    patientName?: string
    dateOfBirth?: string
    imageDate?: string
  }
  generalComment?: string
  changes?: UserChange[]
  additions?: Array<{ type: string; subtype?: string; toothName: number }>
  removedTeeth?: Array<{ toothName: number }>
  addedTeeth?: Array<{ toothName: number }>
  movedTeeth?: Record<string, number>
  addedComments?: Array<{ comment: string; toothName: number }>
  cariesPro?: boolean
  bonelossPro?: boolean
  forms?: { boneLoss: BoneLossFormFull }
}

export interface IImageManipulation {
  brightness: number
  contrast: number
  saturation: number
}
interface IRequestEvent {
  action: string
  resultId: string
  editorOpenMs?: number
  button?: string
  imageManipulation?: IImageManipulation
  usedFullscreen?: boolean
}

export const requestUploadImage = (
  data: IRequestUploadImage,
  params: ContextQuery
) => fetch.post("/upload/", { ...data, ["frontend-upload"]: true }, { params })
export const requestSendChanges = (
  data: IRequestSendReport,
  params: ContextQuery
) => fetch.post("/changes/", { ...data }, { params })

export const requestSendReportProblem = (problem: IReportProblem) =>
  fetch.post(`/service-ticket/`, problem)
export const requestSendEvent = (data: IRequestEvent) =>
  fetch.post("/event/", data)
