import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import { getBonelossPro } from "./image"

export const getFiltersState = ({ filters }: RootState) => filters

export const getShowCaries = ({ filters }: RootState) =>
  filters.showCaries && filters.isEnabled
export const getShowApical = ({ filters }: RootState) =>
  filters.showApical && filters.isEnabled
export const getShowOther = ({ filters }: RootState) =>
  filters.showOther && filters.isEnabled
export const getIsEnabled = ({ filters }: RootState) => filters.isEnabled
export const getActiveNames = ({ filters }: RootState) => ({
  restorations: filters.showOther && filters.isEnabled,
  apical: filters.showApical && filters.isEnabled,
  caries: filters.showCaries && filters.isEnabled,
  calculus: filters.showOther && filters.isEnabled,
  nervus: filters.showOther && filters.isEnabled,
  impacted: filters.showOther && filters.isEnabled,
  annotate: filters.showOther && filters.isEnabled,
})

export const getShowNerveCanal = createSelector(
  [getBonelossPro, getFiltersState],
  (bonelossPro, filtersState) =>
    bonelossPro ? false : filtersState.showNerveCanal
)
