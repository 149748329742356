import { ICroppedTeeth } from "library/common/types/dataStructureTypes"
import { flipTooth } from "@dentalxrai/transform-landmark-to-svg"
import { CroppedTeethDirection } from "library/common/types/adjustmentTypes"

const flipCroppedTeethDirection = (value: CroppedTeethDirection) => {
  //CroppedToothDirection with "lr" is not being supported yet.
  switch (value) {
    case CroppedTeethDirection.right:
      return CroppedTeethDirection.left
    case CroppedTeethDirection.left:
      return CroppedTeethDirection.right
    default:
      return CroppedTeethDirection.none
  }
}

const flipCroppedTeeth = (
  croppedTeeth: ICroppedTeeth | undefined,
  flip: boolean
) => {
  if (!croppedTeeth || !flip) return croppedTeeth
  return Object.fromEntries(
    Object.entries(croppedTeeth).map(([tooth, dir]) => [
      flipTooth(+tooth),
      flipCroppedTeethDirection(dir),
    ])
  )
}
export default flipCroppedTeeth
