import { setLanguage } from "core/i18n/utils"
import { ActionUserInfo, Theme } from "library/common/actions/user"
import { hideToken } from "library/utilities/urls"
import * as React from "react"

export const ParamContext = React.createContext<
  | {
      sourceParam: string
      directParam: string
      showParam: string
      boneLossParam: string
      hsmParam: string
      dynamicPblParam: string
      demoParam: string
      handlerParam: string
      tokenParam: string
      langParam: string
      themeTestParam: string
      legacyLoginParam: string
      codeParam: string
      errorParam: string
      providerParam: string
      resultParam: string
      displayFlippedButtonParam: string
      reportParam: string
      companyIdParam?: string
      patientIdParam?: string
      doctorIdParam?: string
      isIteroScannerUser: boolean
      impersonateParam?: string
    }
  | undefined
>(undefined)

export interface IUrlParamsContext {
  children: React.ReactNode
  theme: Theme
  setUserInfo: (value: ActionUserInfo) => unknown
  setLang: (lang: string) => void
}

export const UrlParamContext = ({
  children,
  theme,
  setUserInfo,
  setLang,
}: IUrlParamsContext) => {
  const params = React.useMemo(() => new URLSearchParams(location.search), [])
  const [tokenParam, setTokenParam] = React.useState("")
  const getParam = (value: string) => params.get(value) || ""

  const companyIdRaw = params.get("companyId")
  const doctorIdRaw = params.get("doctorId")
  const patientIdRaw = params.get("patientId")

  const sourceParam = getParam("source").toLowerCase()
  const directParam = getParam("direct")
  const showParam = getParam("show")
  const langParam = getParam("lang")
  const boneLossParam = getParam("boneLoss")
  const hsmParam = getParam("hsm")
  const dynamicPblParam = getParam("dynamicPbl")
  const demoParam = getParam("demo")
  const handlerParam = getParam("handler")
  const themeTestParam = getParam("themeTest")
  const legacyLoginParam = getParam("legacyLogin")
  const codeParam = getParam("code")
  const errorParam = getParam("error")
  const providerParam = getParam("provider")
  const resultParam = getParam("result")
  const reportParam = getParam("report")
  const displayFlippedButtonParam = getParam("displayFlippedButton")
  // Axios omits the param if value is undefined, otherwise it includes key with empty value for null or ""
  const companyIdParam = companyIdRaw || undefined
  const doctorIdParam = doctorIdRaw || undefined
  const patientIdParam = patientIdRaw || undefined
  const impersonateParam = params.get("impersonate") || undefined

  const isIteroScannerUser = sourceParam === "desktop"

  React.useEffect(() => {
    setUserInfo({
      companyId: companyIdParam,
      doctorId: doctorIdParam,
      isIteroScannerUser,
    })
  }, [companyIdParam, setUserInfo, doctorIdParam, isIteroScannerUser])

  React.useEffect(() => {
    setLanguage(langParam, setLang, theme)
  }, [langParam, setLang, theme])

  React.useEffect(() => {
    const token = params.get("token")
    if (token) {
      setTokenParam(token)
      hideToken(params)
    }
  }, [params])

  return (
    <ParamContext.Provider
      value={{
        sourceParam,
        directParam,
        showParam,
        boneLossParam,
        hsmParam,
        dynamicPblParam,
        demoParam,
        handlerParam,
        tokenParam,
        langParam,
        themeTestParam,
        legacyLoginParam,
        codeParam,
        errorParam,
        providerParam,
        resultParam,
        displayFlippedButtonParam,
        reportParam,
        companyIdParam,
        patientIdParam,
        doctorIdParam,
        isIteroScannerUser,
        impersonateParam,
      }}
    >
      {children}
    </ParamContext.Provider>
  )
}

export const useUrlParamContext = () => {
  const context = React.useContext(ParamContext)

  if (!context) {
    throw new Error("Cannot be used without ParamContext")
  }

  return context
}
