import { RootState } from "core/store/configureStore"
import { SHOW_CARIES_PRO_FOR_PERI } from "library/utilities/constants"
import { createSelector } from "reselect"
import { Kind } from "../types/serverDataTypes"
import { getCariesProProfile, getBonelossProProfile } from "./user"
import { canHaveBoneLossPro } from "./utils/features"

export const getIsProcessed = (state: RootState) => state.image.isProcessed
export const getAnnotationsShown = (state: RootState) =>
  state.image.annotationsShown

export const getOpenDateMs = (state: RootState) => state.image.openDateMs
export const getPdfReportData = (state: RootState) => state.image.pdfReportData
export const getTextAnnotations = (state: RootState) =>
  state.image.textAnnotations

export const getShowImmediately = (state: RootState) =>
  state.image.showImmediately

export const getRawCariesPro = (state: RootState) => state.entities.caries

export const getRawBoneLoss = (state: RootState) => state.entities.boneloss

export const getAcceptMissingMetadata = (state: RootState) =>
  state.image.acceptMissingMetadata

// CARIES PRO
// Only available on bitewings and peri currently
export const getIsCariesProAvailableOnImage = (state: RootState) =>
  state.serverData.present.imageMeta.kind === Kind.Bw ||
  (SHOW_CARIES_PRO_FOR_PERI &&
    state.serverData.present.imageMeta.kind === Kind.Peri &&
    state.user.toothBasedPeri)
// when we don't have caries pro available we're in the undefined cariesPro state
// undefined cariesPro removes the key from the changes JSON, which we want to happen unless we're in BW
export const getCariesPro = createSelector(
  [getIsCariesProAvailableOnImage, getCariesProProfile, getRawCariesPro],
  (isCariesProAvailable, cariesProProfile, rawCariesPro) =>
    isCariesProAvailable && rawCariesPro.every((r) => r.location !== undefined) // CariesPro exists on radiograph
      ? cariesProProfile
      : undefined
)

// BONELOSS
export const getBonelossProAvailable = (state: RootState) =>
  canHaveBoneLossPro(state.serverData.present.imageMeta.kind)
// when we don't have boneloss pro available we're in the undefined bonelossPro state
// undefined bonelossPro removes the key from the changes JSON, which we want to happen unless we're in OPG
export const getBonelossPro = createSelector(
  [getBonelossProAvailable, getBonelossProProfile, getRawBoneLoss],
  (bonelossProAvailable, bonelossProProfile, rawBoneLoss) =>
    bonelossProAvailable && rawBoneLoss !== undefined
      ? bonelossProProfile
      : null
)

export const getRawHSM = (state: RootState) =>
  state.serverData.present.imageMeta.kind === Kind.Other
    ? false
    : state.filters.isHighSenseModeActive

export const getIsHighSenseModeActive = createSelector(
  [getRawHSM, getBonelossPro],
  (hsm, bonelossPro) => !bonelossPro && hsm
)
