import { flipTooth } from "@dentalxrai/transform-landmark-to-svg"
import { UserChange } from "library/common/types/dataStructureTypes"
import { NumberingSystem } from "library/common/types/userTypes"

export const visualQuadrant = (tooth: number, flip: boolean) =>
  Math.floor(flipTooth(tooth, flip) / 10)

export function flipChanges(changes: UserChange[], flip = true): UserChange[] {
  return flip
    ? changes.map((change) =>
        change.newTooth
          ? {
              ...change,
              newTooth: flipTooth(change.newTooth),
            }
          : change
      )
    : changes
}

export function flipMovedTeeth(
  movedTeeth: Record<string, number>
): Record<string, number> {
  const newMovedTeeth: Record<string, number> = {}
  Object.entries(movedTeeth).forEach(([k, v]) => {
    newMovedTeeth[`${flipTooth(+k)}`] = flipTooth(v)
  })
  return newMovedTeeth
}

export const displayToothName = (tooth: number, system: NumberingSystem) => {
  if (system !== NumberingSystem.universal) return tooth

  switch (Math.floor(tooth / 10)) {
    case 1:
      return 19 - tooth
    case 2:
      return tooth - 12
    case 3:
      return 55 - tooth
    case 4:
      return tooth - 16
    default:
      return 0
  }
}
