import React from "react"

export const ImageIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.75 1.5H11.25V3.75H12.75V1.5Z" fill="currentColor" />
    <path d="M22.5 11.25H20.25V12.75H22.5V11.25Z" fill="currentColor" />
    <path d="M12.75 20.25H11.25V22.5H12.75V20.25Z" fill="currentColor" />
    <path d="M3.75 11.25H1.5V12.75H3.75V11.25Z" fill="currentColor" />
    <path
      d="M5.1481 4.10198L4.08744 5.16264L5.67843 6.75363L6.73909 5.69297L5.1481 4.10198Z"
      fill="currentColor"
    />
    <path
      d="M18.8407 4.09468L17.2497 5.68568L18.3104 6.74634L19.9014 5.15534L18.8407 4.09468Z"
      fill="currentColor"
    />
    <path
      d="M18.3124 17.2517L17.2517 18.3123L18.8427 19.9033L19.9034 18.8427L18.3124 17.2517Z"
      fill="currentColor"
    />
    <path
      d="M4.1025 18.8475L5.6925 17.25L6.75 18.315L5.16 19.905L4.1025 18.8475Z"
      fill="currentColor"
    />
    <path
      d="M12 6C10.8133 6 9.65327 6.35189 8.66658 7.01118C7.67988 7.67047 6.91085 8.60754 6.45672 9.7039C6.0026 10.8003 5.88378 12.0067 6.11529 13.1705C6.3468 14.3344 6.91824 15.4035 7.75736 16.2426C8.59647 17.0818 9.66557 17.6532 10.8295 17.8847C11.9933 18.1162 13.1997 17.9974 14.2961 17.5433C15.3925 17.0892 16.3295 16.3201 16.9888 15.3334C17.6481 14.3467 18 13.1867 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM12 16.5C10.8065 16.5 9.66193 16.0259 8.81802 15.182C7.97411 14.3381 7.5 13.1935 7.5 12C7.5 10.8065 7.97411 9.66193 8.81802 8.81802C9.66193 7.97411 10.8065 7.5 12 7.5V16.5Z"
      fill="currentColor"
    />
  </svg>
)
