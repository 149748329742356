import {
  fork,
  takeLatest,
  call,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"

import * as imageActions from "library/common/actions/image"
import * as entitiesActions from "library/common/actions/entities"
import * as teethActions from "library/common/actions/teeth"
import * as filtersActions from "library/common/actions/filters"
import * as imageControlsActions from "library/common/actions/imageControls"
import * as adjustmentsActions from "library/common/actions/adjustments"
import * as drawingActions from "library/common/actions/drawing"
import * as webSocketActions from "library/common/actions/webSocket"
import * as serverDataActions from "library/common/actions/serverData"
import {
  setServerError,
  setServerErrorMessage,
  setUserInfo,
  Theme,
} from "library/common/actions/user"
import * as patientActions from "library/common/actions/patient"

import * as imageAPIs from "library/services/imageApis"
import * as routeSelectors from "library/common/selectors/routes"
import {
  imageTypes,
  PdfReportData,
  PdfData,
} from "library/common/types/imageTypes"
import {
  BoneLossFormUser,
  IMeta,
  Kind,
} from "library/common/types/serverDataTypes"
import {
  Detection,
  UserChange,
  Boneloss,
  ToothSegment,
  HistoricalResult,
  NerveCanal,
  ICroppedTeeth,
} from "library/common/types/dataStructureTypes"
import {
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { Comment } from "../types/serverDataTypes"
import { getLang } from "library/common/selectors/i18n"
import {
  requestSendChanges,
  requestSendChangesComplete,
  setDataIsChanged,
} from "../actions/saving"
import { history } from "core/store/configureStore"
import {
  getShowImmediately,
  getBonelossPro,
  getAnnotationsShown,
} from "../selectors/image"
import { SaveComplete, SavingType } from "../types/savingTypes"
import { closeModal, openModal } from "../actions/modal"
import {
  getContextQueryParams,
  getModalities,
  getShowToothBasedPeri,
  getTheme,
} from "../selectors/user"
import {
  getAllUserChanges,
  getIsImageHorizontallyFlipped,
  getIsOutdatedAnalysis,
  getKind,
  getPatientUuid,
  getToothBasedPeri,
} from "../selectors/serverData"
import { getEntities } from "../selectors/entities"
import { Entities } from "../types/entitiesTypes"
import {
  FEATURE_NERVE_CANAL,
  SHOW_PDF_VERSION,
  TOOTH_MAP_POSITION_ASPECT_RATIO,
} from "library/utilities/constants"
import { Modals } from "../reducers/modalsReducer"
import { setLegend } from "../actions/legend"
import localStorage from "library/utilities/localStorage"
import {
  getNewAIVersionModalShownIds,
  getOpenedModal,
} from "../selectors/modals"
import { getPatientFileBreadcrumb } from "../selectors/breadcrumbs"
import {
  setImageIDBreadcrumb,
  setPatientFileBreadcrumb,
} from "../actions/breadcrumbs"
import { patientFileUrl } from "library/utilities/urls"
import { getActivePatientUuid } from "../selectors/patient"
import { ContextQuery } from "../types/userTypes"

export interface IAnalysisResult {
  data: IAnalysisData
}

interface IRotationData {
  incorrectOrientation: boolean
  rotationConfidence: number
}

interface IAnalysisData {
  id?: string
  apical?: Detection[]
  boneLoss?: Boneloss
  caries?: Detection[]
  restorations?: Detection[]
  changes?: UserChange[]
  additions?: AnnotationOnTooth[]
  addedComments?: Comment[]
  generalComment?: string
  addedTeeth?: Tooth[]
  removedTeeth?: Tooth[]
  movedTeeth?: Record<string, number>
  teeth?: Tooth[]
  meta?: IMeta
  cariesPro?: boolean
  bonelossPro?: boolean
  status: string
  message?: string
  forms?: { boneLoss: BoneLossFormUser }
  calculus?: Detection[]
  nervus?: Detection[]
  segments?: ToothSegment[]
  impacted?: Detection[]
  historicalResults?: HistoricalResult[]
  rotation?: IRotationData
  nerveCanal?: NerveCanal[]
  viewed: boolean
  croppedTeeth?: ICroppedTeeth
}

function* cleanCachedImageSaga(skip: any) {
  yield put(entitiesActions.setInitialState())
  yield put(teethActions.setInitialState())
  if (!skip.image) yield put(imageActions.setInitialState())
  yield put(imageActions.setAsNotProcessed())
  yield put(filtersActions.setInitialState())
  yield put(imageControlsActions.setInitialState())
  yield put(adjustmentsActions.setInitialState())
  yield put(serverDataActions.setInitialState())
  yield put(drawingActions.setInitialState())
  yield put(patientActions.setInitialState())
}

// reject boneLoss that doesn't fit our expected format
const cleanBoneLoss = (boneLoss: any) =>
  boneLoss?.annotations ? boneLoss : undefined

// reject nervus that doesn't fit our expected format
const cleanNervus = (nervus: any) =>
  nervus && nervus[0]?.annotations ? [] : nervus

export function* updateImageDataSaga(data: IAnalysisData) {
  const modalities: string = yield select(getModalities)
  // Only show detections when kind is supported
  if (!data.meta || modalities.includes(data.meta.kind)) {
    yield put(
      entitiesActions.saveAnnotations({
        apical: data.apical || [],
        boneloss: cleanBoneLoss(data.boneLoss),
        caries: data.caries || [],
        restorations: data.restorations || [],
        detectedTeeth: data.teeth || [],
        calculus: data.calculus || [],
        nervus: cleanNervus(data.nervus) || [],
        segments: data.segments || [],
        impacted: data.impacted || [],
        historicalResults: data.historicalResults || [],
        nerveCanal: data.nerveCanal || [],
        croppedTeeth: data.croppedTeeth || undefined,
      })
    )
  }
  yield put(entitiesActions.setImageId(data.id || ""))
  yield put(entitiesActions.setViewed(data.viewed || false))
  yield put(serverDataActions.addUserAdditions(data.additions || []))
  yield put(serverDataActions.addUserChanges(data.changes || []))
  yield put(serverDataActions.userAddAddedTeeth(data.addedTeeth || []))
  yield put(serverDataActions.userAddDeletedTeeth(data.removedTeeth || []))
  yield put(serverDataActions.addAddedComments(data.addedComments || []))
  yield put(serverDataActions.setGeneralComment(data.generalComment || ""))
  yield put(serverDataActions.setMovedTeeth(data.movedTeeth || {}))
  if (data.forms) {
    yield put(serverDataActions.saveBoneLossForm(data.forms.boneLoss))
  }

  if (data.meta) {
    const meta = {
      ...data.meta,
      isImageHorizontallyFlipped: !!data.meta.isImageHorizontallyFlipped,
      angleImageRotation: data.meta.angleImageRotation || 0,
      ...((data.meta.angleImageRotation === 90 ||
        data.meta.angleImageRotation === 270) && {
        imageWidth: data.meta.imageHeight,
        imageHeight: data.meta.imageWidth,
      }),
      isOwner: data.meta.isOwner ?? true,
      outdatedAnalysis: data.meta.outdatedAnalysis && data.status === "done",
    }
    yield put(serverDataActions.saveImageMeta(meta))
    if (meta.kind === Kind.Other) {
      // don't show detections for OTHER
      yield put(imageActions.setShowImmediately(false))
    }
  }
  const { cariesPro, bonelossPro, status, message } = data
  const theme: Theme = yield select(getTheme)
  yield put(
    setUserInfo({
      bonelossPro: theme === Theme.carestream ? false : bonelossPro,
      cariesPro,
    })
  )

  const isLegendOpen = localStorage.getItem("is_legend_open")
  // Before the value is set, show it as open
  if (isLegendOpen === null) {
    yield put(setLegend(true))
    localStorage.setItem("is_legend_open", true)
  } else {
    yield put(setLegend(isLegendOpen))
  }
  const isReportPage = history.location.pathname.includes("report")

  if (data.rotation?.incorrectOrientation && !isReportPage) {
    yield put(openModal(Modals.ROTATE_OPTIONS_MODAL))
  }

  const isOutdatedAnalysis: boolean = yield select(getIsOutdatedAnalysis)
  const openedModal: Modals = yield select(getOpenedModal)
  const newAIVersionModalShownIds: string[] = yield select(
    getNewAIVersionModalShownIds
  )
  const imageId: string = yield select(routeSelectors.getRouteImageId)

  if (
    // Old AI version
    (isOutdatedAnalysis &&
      openedModal !== Modals.TOOTH_BASED_PERI_ALERT &&
      !isReportPage &&
      !newAIVersionModalShownIds.includes(imageId)) ||
    // Has new nerveCanal available
    (FEATURE_NERVE_CANAL && data.nervus && !data.nerveCanal)
  ) {
    yield put(openModal(Modals.NEW_AI_VERSION_MODAL))
  }

  // Set largerAspectRatio ratio here for initial tooth map position to prevent jumping around
  yield put(
    imageControlsActions.setIsLargerAspectRatioScreen(
      window.innerWidth / window.innerHeight > TOOTH_MAP_POSITION_ASPECT_RATIO
    )
  )

  const inferenceStatus = {
    status,
    message,
  }
  yield put(serverDataActions.setInferenceStatus(inferenceStatus))

  if (status === "done" || status === "error") {
    yield put(imageActions.loadAnnotationsSuccess())
  } else if (data.id) {
    yield put(webSocketActions.connect(data.id))
  }

  yield put(setImageIDBreadcrumb(`/dashboard/${imageId}`))

  /*
  Only set patientFileBreadcrumb if it's not set yet. i.e. after refresh
  */
  const patientFileBreadcrumb: string = yield select(getPatientFileBreadcrumb)
  if (patientFileBreadcrumb) return

  const patientUuid: string = yield select(getPatientUuid)
  yield put(setPatientFileBreadcrumb(patientFileUrl(patientUuid)))
}

function* loadAnnotationsSaga() {
  try {
    const id: string = yield select(routeSelectors.getRouteImageId)
    const params: ContextQuery = yield select(getContextQueryParams)
    if (id) {
      const { data }: IAnalysisResult = yield call(
        imageAPIs.requestImageAnalysis,
        id,
        params
      )
      yield call(updateImageDataSaga, data)
      const showImmediately: boolean = yield select(getShowImmediately)
      if (
        showImmediately &&
        data.status !== "error" &&
        data.meta?.kind !== Kind.Other
      ) {
        yield put(imageActions.showAnnotations(true))
      }
    } else {
      yield put(setServerError("unavailable"))
      yield put(setServerErrorMessage("invalid uuid"))
    }
  } catch (error) {
    yield put(
      setServerErrorMessage(error.response?.data?.error || "Unexpected Error")
    )
  }

  const entities: Entities = yield select(getEntities)
  const userChanges: UserChange[] = yield select(getAllUserChanges)
  const changedIds = userChanges.flatMap((u) => u.annotationId)

  const keys = [
    AnnotationName.apical,
    AnnotationName.calculus,
    AnnotationName.caries,
    AnnotationName.nervus,
    AnnotationName.restorations,
    AnnotationName.impacted,
  ]

  // Only work with applicable entities
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const filteredEntities = keys.flatMap((k) => entities[k] || [])
  const changedTeeth = filteredEntities.flatMap(
    (f) => (changedIds.includes(f.id) && f.toothName) || []
  )

  const uniqueChangedTeeth = new Set(changedTeeth)

  // Extract tooth names where a certain restoration appears
  const getAffectedTeeth = (subtype: RestorationSubtype) =>
    entities.restorations
      .filter((e) => e.subtype === subtype)
      .map((e) => e.toothName)

  const filteredBridges = getAffectedTeeth(RestorationSubtype.bridges)
  const filteredImplants = getAffectedTeeth(RestorationSubtype.implants)

  const rejectedIds = filteredEntities
    .filter(
      (f) =>
        !uniqueChangedTeeth.has(f.toothName) &&
        ((f.subtype !== RestorationSubtype.bridges &&
          filteredBridges.includes(f.toothName)) ||
          (f.subtype !== RestorationSubtype.bridges &&
            f.subtype !== RestorationSubtype.crowns &&
            f.subtype !== RestorationSubtype.implants &&
            filteredImplants.includes(f.toothName)))
    )
    .map((entity) => entity.id)

  yield put(
    serverDataActions.addUserChanges(
      rejectedIds.map((detectionId) => ({
        action: "rejected",
        annotationId: detectionId,
      }))
    )
  )
}

// Reset the local data and send the reanalysis request to the server
export function* reanalyseImage(payload: imageAPIs.ReanalyzeRequest) {
  const params: ContextQuery = yield select(getContextQueryParams)
  yield call(cleanCachedImageSaga, { image: true })
  try {
    const { data }: IAnalysisResult = yield call(
      imageAPIs.requestReanalyze,
      payload,
      params
    )
    yield fork(updateImageDataSaga, data)
  } catch (error) {
    console.error(error)
  }
}

// Reset the analysis
export function* reanalyseImageSaga() {
  const id: string = yield select(routeSelectors.getRouteImageId)
  const annotationsShown: boolean = yield select(getAnnotationsShown)

  yield put(requestSendChanges(SavingType.ALL))
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )
  if (payload.success) {
    yield call(reanalyseImage, { id })
  }

  if (annotationsShown) {
    yield put(imageActions.showAnnotations(true))
  }
  yield put(closeModal())
}

// Reset the analysis and rotate 180 degrees
function* rotateImageSaga({
  payload: { rotate, isFlipped },
}: ReturnType<typeof imageActions.rotateImage>) {
  const isImageHorizontallyFlipped: boolean = yield select(
    getIsImageHorizontallyFlipped
  )
  if (isFlipped !== isImageHorizontallyFlipped) {
    yield put(serverDataActions.flipImage())
  }
  if (rotate) {
    const id: string = yield select(routeSelectors.getRouteImageId)
    yield call(reanalyseImage, { id, rotate, isFlipped })
  }

  yield put(closeModal())
}

// Reset the analysis and change the image kind
function* changeRadiographTypeSaga({
  payload: kind,
}: ReturnType<typeof imageActions.changeRadiographType>) {
  const id: string = yield select(routeSelectors.getRouteImageId)
  yield call(reanalyseImage, { id, kind })
}

function* loadImageSaga() {
  yield call(cleanCachedImageSaga, {})
  yield put(imageActions.loadImageSuccess())
}

export function* resetOpenDataMsSaga() {
  yield put(imageActions.updateOpenDateMs())
}

export function* loadPdfReportSaga({
  payload: PdfReport,
}: ReturnType<typeof imageActions.loadPdfReport>) {
  const lang: string = yield select(getLang)
  const boneLossPro: boolean | null = yield select(getBonelossPro)
  const activePatientUuid: string | null = yield select(getActivePatientUuid)
  const params: ContextQuery = yield select(getContextQueryParams)
  try {
    const pdfData: PdfData = yield call(
      boneLossPro || PdfReport.isBoneLossPdf // Bone loss exists and is toggled on or url contains report-bone-loss
        ? imageAPIs.requestBoneLossPdfReport
        : imageAPIs.requestPdfReport,
      PdfReport.id,
      lang,
      "xrayinsights", // TODO: allow other themes in the future
      SHOW_PDF_VERSION,
      activePatientUuid,
      params
    )
    const data = pdfData.data
    if (!data.pdf) {
      throw Error("PDF Report could not be loaded, empty pdf key in response.")
    }
    const pdfDataPayload: PdfReportData = {
      pdfReportData: data.pdf,
      textAnnotations: data.text,
    }
    yield put(imageActions.loadPdfReportSuccess(pdfDataPayload))
  } catch (error) {
    console.error(error)
  }
}

export function* openPdfReportSaga() {
  yield put(setDataIsChanged(false)) // Prevent save alert to pop up.
  yield put(requestSendChanges(SavingType.ALL))
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )
  const boneLossPro: boolean | null = yield select(getBonelossPro)
  const isIntegrated: boolean = yield select(routeSelectors.getIsIntegrated)
  if (payload.success) {
    const prefix = isIntegrated ? "integrated-" : ""
    const suffix = boneLossPro ? "-bone-loss" : ""
    history.push(`/${prefix}report${suffix}/${payload.id}`)
  }
}

export function* revertImageSaga({
  payload: id,
}: ReturnType<typeof imageActions.revertImage>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const annotationsShown: boolean = yield select(getAnnotationsShown)
  const params: ContextQuery = yield select(getContextQueryParams)

  try {
    yield call(imageAPIs.revertImage, resultId, id, params)
    yield call(cleanCachedImageSaga, {})
    yield put(imageActions.loadImageSuccess())
    if (annotationsShown) {
      yield put(imageActions.showAnnotations(true))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* loadAnnotationsSuccess() {
  const toothBasedPeri: boolean = yield select(getToothBasedPeri)
  const showToothBasedPeri: boolean = yield select(getShowToothBasedPeri)
  const kind: Kind = yield select(getKind)

  if (kind === Kind.Peri && !toothBasedPeri && showToothBasedPeri) {
    yield put(openModal(Modals.TOOTH_BASED_PERI_ALERT))
  }
}

export default function* entitiesSaga() {
  yield takeLatest(imageTypes.LOAD_IMAGE, loadImageSaga)
  yield takeLatest(imageTypes.ROTATE_IMAGE, rotateImageSaga)
  yield takeEvery(
    [imageTypes.IMAGE_PROCESSING_COMPLETE, imageTypes.LOAD_IMAGE_SUCCESS],
    loadAnnotationsSaga
  )
  yield takeLatest(imageTypes.CHANGE_RADIOGRAPH_TYPE, changeRadiographTypeSaga)
  yield takeLatest(imageTypes.REVERT_IMAGE, revertImageSaga)
  yield takeLatest(imageTypes.SHOW_ANNOTATIONS, resetOpenDataMsSaga)
  yield takeLatest(imageTypes.LOAD_PDF_REPORT, loadPdfReportSaga)
  yield takeLatest(imageTypes.OPEN_PDF_REPORT, openPdfReportSaga)
  yield takeEvery(imageTypes.REANALYZE_IMAGE, reanalyseImageSaga)
  yield takeEvery(imageTypes.LOAD_ANNOTATIONS_SUCCESS, loadAnnotationsSuccess)
}
