import {
  AnnotationOnTooth,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"
import { Detection } from "library/common/types/dataStructureTypes"

export const subtypeIsCrownOrBridge = (subtype: string | undefined) =>
  subtype === RestorationSubtype.bridges ||
  subtype === RestorationSubtype.crowns

export const crownOrBridgeOverride = (
  bonelossPro: boolean | null,
  additions: AnnotationOnTooth[],
  detection: Detection
) => {
  const crownBridgeOverride = new Map<number, RestorationSubtype>()
  if (!bonelossPro) {
    additions.forEach((a) => {
      if (subtypeIsCrownOrBridge(a.subtype)) {
        crownBridgeOverride.set(a.toothName, a.subtype!)
      }
    })
  }

  // calculate the overwritten subtype for recoloring the annotation
  return subtypeIsCrownOrBridge(detection.subtype) &&
    detection.subtype !== crownBridgeOverride.get(detection.toothName)
    ? crownBridgeOverride.get(detection.toothName)
    : undefined
}
