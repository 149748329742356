import { RootState } from "core/store/configureStore"

export const getPatientListResult = (state: RootState) =>
  state.patients.patientListResult
export const getActivePatientResult = (state: RootState) =>
  state.patients.activePatientResult
export const getActivePatientUuid = (state: RootState) =>
  state.patients.activePatientResult.patient?.patientUuid

export const getPagination = (state: RootState) => state.patients.pagination
export const getFilterValues = (state: RootState) => state.patients.filterValues
export const getFilteredStatus = (state: RootState) =>
  state.patients.filteredStatus
export const getIsPatientMatchingMode = (state: RootState) =>
  state.patients.isPatientMatchingMode
export const getPatientMatch = (state: RootState) => state.patients.patientMatch
