import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { isBrowserValid } from "library/utilities/browser"
import {
  DEFAULT_THEME,
  OAUTH_BASE_URI,
  OAUTH_PROVIDER_NAME,
} from "library/utilities/constants"
import { Icon } from "components/atoms/Icon"
import { theme } from "@align-cpf/uikit-styles"
import { defaultRoute } from "library/utilities/defaultRoute"

const Auth = React.lazy(() => import("pages/Auth"))
const AuthOauth = React.lazy(() => import("pages/AuthOauth"))
const DashboardContainer = React.lazy(
  () => import("pages/Dashboard/DashboardContainer")
)
const AuthSSO = React.lazy(() => import("pages/AuthSSO"))
const ResetPassword = React.lazy(() => import("pages/ResetPassword"))
const ReportContainer = React.lazy(() => import("pages/Report/ReportContainer"))
const Upload = React.lazy(() => import("pages/Upload"))
const UpdateBrowser = React.lazy(() => import("pages/UpdateBrowser"))
const ProtectedLayout = React.lazy(
  () => import("library/common/components/Layouts/ProtectedLayout/")
)
const PatientsList = React.lazy(() => import("pages/Patients/PatientList"))
const PatientsFile = React.lazy(() => import("pages/Patients/PatientFile"))
const UserManual = React.lazy(() => import("pages/UserManual"))

const Routes = () => {
  if (!isBrowserValid) return <UpdateBrowser />

  if (!document.body.className) {
    document.body.className = `theme-${DEFAULT_THEME}`
  }

  return (
    <Suspense
      fallback={
        <div
          style={{
            background: theme.colors.backgroundSubtle00,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Icon type="processing" size={32} />
        </div>
      }
    >
      <Switch>
        <Redirect exact from="/" to={defaultRoute()} />
        {/* /archive is kept for legacy support */}
        <Redirect exact from="/archive" to={"/patients"} />
        <Route exact path="/login" component={Auth} />
        {OAUTH_BASE_URI && OAUTH_PROVIDER_NAME && (
          <Route exact path="/login/wso2" component={AuthOauth} />
        )}
        <Route exact path="/sso-login" component={AuthSSO} />
        <Route path="/onboarding" component={ResetPassword} />
        <Route
          path="/"
          render={({ history, match, location }) => (
            <ProtectedLayout
              history={history}
              match={match}
              location={location}
            >
              <Switch>
                <Route path="/dashboard/:id" component={DashboardContainer} />
                <Route path="/integrated/:id" component={DashboardContainer} />
                <Route path="/report/:id" component={ReportContainer} />
                <Route
                  path="/integrated-report/:id"
                  component={ReportContainer}
                />
                <Route
                  path="/integrated-report-bone-loss/:id"
                  component={ReportContainer}
                />
                <Route
                  path="/report-bone-loss/:id"
                  component={ReportContainer}
                />
                <Route path="/upload" component={Upload} />
                <Route path="/patients/:patientID" component={PatientsFile} />
                <Route
                  path="/unassigned-patient/:patientName"
                  component={PatientsFile}
                />
                <Route path="/unassigned-patient" component={PatientsFile} />
                <Route path="/patients" component={PatientsList} />
                <Route path="/user-manual" component={UserManual} />
                <Redirect to={defaultRoute()} />
              </Switch>
            </ProtectedLayout>
          )}
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
