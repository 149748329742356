import { createAction } from "typesafe-actions"

import { Licence, NumberingSystem, Role, UserTypes } from "../types/userTypes"

export interface ActionLogin {
  isRemember: boolean
  isSSO: boolean
  email: string
  password?: string
  redirect_uri?: string
  directLoginUsername?: string
  isRefreshToken?: boolean
}

export interface ActionResetPasswordFirstTime {
  newPassword: string
  fullName: string
  email: string
  telephoneNumber: string
  token: string
}

export enum Theme {
  dentalxrai = "dentalxrai",
  carestream = "carestream",
}

export enum CoachMarkFeature {
  cariesPro = "cariesPro",
  autoCariesPro = "autoCariesPro",
  boneLossPro = "boneLossPro",
  calculusXrayImage = "calculusXrayImage",
  calculusToothMap = "calculusToothMap",
  calculusEditView = "calculusEditView",
  cariesOnOpg = "cariesOnOpg",
}

export enum CoachMarkIndex {
  first = 1,
}

export type CoachMarkRecord = Record<CoachMarkFeature, CoachMarkIndex>

export type GeneratedToken = {
  token: string
  sessionId?: string
}

export interface ActionUserInfo {
  fullName?: string
  email?: string
  SSO?: boolean
  knownHandlers?: string[]
  cariesPro?: boolean
  bonelossPro?: boolean
  boneLossLite?: boolean
  uploadsRemaining?: number
  whatsNew?: string
  lastCoachMarks?: CoachMarkRecord
  theme?: Theme
  calculus?: boolean
  nervus?: boolean
  impacted?: boolean
  licence?: Licence
  licenceExpire?: string
  showDrawingMode?: boolean
  boneLossOnly?: boolean
  mustResetPassword?: boolean
  modalities?: string[]
  numberingSystem?: NumberingSystem
  toothBasedPeri?: boolean
  role?: Role
  companyId?: string
  doctorId?: string
  isIteroScannerUser?: boolean
  installerByDefault?: boolean
}

export interface ActionLastCoachMark {
  coachMarkFeature: CoachMarkFeature
  coachMarkIndex: CoachMarkIndex
}

export const logOutAction = createAction(UserTypes.LOGOUT_ACTION)()
export const resetErrorAction = createAction(UserTypes.RESET_ERROR_ACTION)()
export const loginAction = createAction(UserTypes.LOGIN_ACTION)()
export const loginActionSuccess = createAction(
  UserTypes.LOGIN_ACTION_SUCCESS
)<ActionLogin>()
export const loginActionError = createAction(
  UserTypes.LOGIN_ACTION_ERROR
)<number>()
export const loginActionWithData = createAction(
  UserTypes.LOGIN_ACTION_WITH_DATA
)<ActionLogin>()
export const resetPasswordAction = createAction(
  UserTypes.RESET_PASSWORD_ACTION
)<{ oldPassword: string; newPassword: string }>()
export const newPasswordErrorAction = createAction(
  UserTypes.NEW_PASSWORD_ERROR_ACTION
)<string>()
export const oldPasswordErrorAction = createAction(
  UserTypes.OLD_PASSWORD_ERROR_ACTION
)<string>()
export const isLoadingResetPasswordAction = createAction(
  UserTypes.IS_LOADING_RESET_PASSWORD_ACTION
)<boolean>()
export const isSuccessResetPassword = createAction(
  UserTypes.IS_SUCCESS_RESETPASSWORD_ACTION
)<boolean>()
export const setMustResetPassword = createAction(
  UserTypes.SET_MUST_RESET_PASSWORD_ACTION
)<boolean>()
export const resetPasswordFirstTimeAction = createAction(
  UserTypes.RESET_PASSWORD_FIRST_TIME_ACTION
)<ActionResetPasswordFirstTime>()
export const getUserName = createAction(UserTypes.GET_USER_NAME)()
export const setUserInfo = createAction(
  UserTypes.SET_USER_INFO
)<ActionUserInfo>()
export const setServerError = createAction(UserTypes.SET_SERVER_ERROR)<string>()
export const setServerErrorMessage = createAction(
  UserTypes.SET_SERVER_ERROR_MESSAGE
)<string>()
export const setHandlerName = createAction(UserTypes.SET_HANDLER_NAME)<string>()
export const setHandlerHash = createAction(UserTypes.SET_HANDLER_HASH)<string>()
export const submitHandlerHash = createAction(UserTypes.SUBMIT_HANDLER_HASH)()
export const toggleCariesPro = createAction(UserTypes.TOGGLE_CARIES_PRO)()
export const toggleBonelossPro = createAction(UserTypes.TOGGLE_BONELOSS_PRO)()
export const toggleDrawingMode = createAction(UserTypes.TOGGLE_DRAWING_MODE)()
export const setBonelossPro = createAction(
  UserTypes.SET_BONELOSS_PRO
)<boolean>()
export const setWhatsNew = createAction(UserTypes.SET_WHATS_NEW)()
export const generateToken = createAction(UserTypes.GENERATE_TOKEN)()
export const setGeneratedToken = createAction(
  UserTypes.SET_GENERATED_TOKEN
)<GeneratedToken>()
export const setLoggedOutByTimeOut = createAction(
  UserTypes.SET_SET_LOGGED_OUT_BY_TIME_OUT
)<boolean>()
export const setLoggedOutByButton = createAction(
  UserTypes.SET_LOGGED_OUT_BY_BUTTON
)<boolean>()

export const setLastCoachMarks = createAction(
  UserTypes.SET_LAST_COACH_MARKS
)<ActionLastCoachMark>()
export const setImpersonate = createAction(UserTypes.SET_IMPERSONATE)<string>()
