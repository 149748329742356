import { Location } from "history"

export const hideToken = (params: URLSearchParams) => {
  params.delete("token")
  window.history.replaceState(null, "", `?${params}`)
}

const encodeTwice = (s: string) => encodeURIComponent(encodeURIComponent(s))

export const patientFileUrl = (patientUuid: string | null) =>
  patientUuid ? `/patients/${encodeTwice(patientUuid)}` : "/unassigned-patient/"

export const isDashboard = (location: Location<unknown>) =>
  location.pathname.startsWith("/dashboard")
